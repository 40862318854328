(function($) {
    $.fn.dtFilter = function(options) {
        let defaults = {
            filters: [],
            appliedFilters: [],
            datatable: undefined
        };

        let settings = $.extend({}, defaults, options);

        const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

        const filter = function() {
            settings.appliedFilters = [];

            $('#dtFilter-appliedFilters ul').children().remove();

            settings.filters.filter((idx, el) => $(el).val() !== null && $(el).val() !== '')
                .each(function(idx, el) {
                    let $el    = $(el),
                        name = $el.attr('name'),
                        value = $el.val(),
                        filter = {};

                    if ($el.prop('tagName') === 'SELECT') {
                        value = $el.find('option:selected').text();
                    }

                    switch ($el.prop('tagName')) {
                        case 'SELECT':
                            value = $el.find('option:selected').text();
                            break;
                        case 'CHECKBOX':
                            value = $el.siblings('label').text();
                            break;
                        case 'RADIO':
                            value = $el.siblings('label').text();
                            break;
                    }

                    filter[name] = value;

                    settings.appliedFilters.push(filter);

                    $('#dtFilter-appliedFilters ul').append(`
                        <li class="list-inline-item">
                            <span class="badge badge-pill badge-warning px-2 py-1 d-flex justify-content-between align-items-center">
                                ${titleCase(name)}: ${value}
                                <a class="text-muted reset-filter ml-2" data-filter="${name}"><i class="fa fa-times"></i></a>
                            </span>
                        </li>
                    `);
                });

            refreshFiltersInfo();

            settings.datatable.draw();
        }

        const removeFilter = function(evt) {
            evt.preventDefault();

            let filterToRemove = $(evt.currentTarget).data('filter');

            settings.appliedFilters = settings.appliedFilters.filter((filter) => ! filter.hasOwnProperty(filterToRemove));
            settings.filters[filterToRemove] = null;

            $(`[name=${filterToRemove}]`).val('').trigger('change');;

            filter();
        }

        const reset = function() {
            $('#dtFilter-appliedFilters ul').children().remove();

            settings.filters.each(function(idx, el) { $(el).val(null).trigger('change');; });

            settings.appliedFilters = [];
            settings.datatable.draw();

            refreshFiltersInfo();
        }

        const refreshFiltersInfo = function() {
            let filtersCount = settings.appliedFilters.length;

            if (filtersCount) {
                $('#dtFilter .dtFilter-applied-filters-counter').text(filtersCount);
                $('#dtFilter-appliedFilters').show();
            } else {
                $('#dtFilter .dtFilter-applied-filters-counter').text(null);
                $('#dtFilter-appliedFilters').hide();
            }
        }

        this.initialize = function() {
            $('#dtFilter-appliedFilters').hide();

            settings.filters = this.find('input, select');

            $(this.find('#apply-filters')).on('click', function(e) {
                e.preventDefault();
                filter();
            });

            $(this).on('click', '.reset-filter', function(e) {
                e.preventDefault();
                removeFilter(e);
            });

            $(this).on('click', '.reset-filters', function(e) {
                e.preventDefault();
                reset();
            });

            filter();

            return this;
        }

        return this.initialize();
    }
})(jQuery);